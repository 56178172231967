<template>
  <div class="flex-col page">
    <div class="flex-col group_1">
      <!-- head -->
      <div class="flex-col text-wrapper">
        <span class="text_1">{{title}}</span>
      </div>
      <!-- 周 -->
      <div class="flex-col section_2">
        <span class="text_2">{{date}}</span>
        <div class="list flex-row group_2">
          <span class="list-item" :key="i" v-for="(item, i) in list1">{{item.item}}</span>
        </div>
      </div>
      <div class="flex-col group_3">
        <div class="flex-col">
          <!-- 日期 -->
          <div class="flex-row">
            <div class="list-item_1 flex-col" :key="i" v-for="(item, i) in list2" @click='lists(item.item,i)'>
              <div :class="['top-text-wrapper','flex-col',{active:i==str}]">
                <span class="text_10">{{item.item}}</span>
              </div>
              <!-- <i class="image_2"></i> -->
            </div>
          </div>
        </div>
        <!-- 待办 -->
        <span class="text_18">{{title_1}}</span>
        <div class="group_8">
          <div class="list_2">
            <div class="list-item_2 flex-row" :key="i" v-for="(item, i) in list3">
              <img class="image_6" :src="item.icon" />
              <span class="text_19">{{item.title}}</span>
              <span class="text_21">{{item.numb}}</span>
            </div>
          </div>
          <!-- 统计 -->
          <span class="text_29">{{title_2}}</span>
          <div class="list_3">
            <div class="list-item_3 justify-between" :key="i" v-for="(item, i) in list4">
              <div class="left-group flex-col view_3">
                <span class="text_30">{{item.title}}</span>
                <span class="text_32">{{item.tips}}单</span>
              </div>
              <img class="image_12" :src="item.icon" />
            </div>
          </div>
        </div>
        <!-- 日志 -->
        <span class="text_40">{{title_3}}</span>
        <div class="flex-col" :key="i" v-for="(date, i) in list5">
          <span class="list-item_4">{{date.date}}</span>
          <div class="list-item_4 flex-row group_13" v-if="date.time">
            <div class="left-group_1 flex-col items-center">
              <span>{{date.time}}</span>
              <img class="image_17" :src="date.icon" />
              <div class="bottom-section"></div>
            </div>
            <div class="right-section flex-col">
              <div class="justify-between">
                <span class="text_43">{{date.title}}</span>
                <div class="right-text-wrapper flex-col items-center" @click="tz(date.state,date.mark,date.id)">
                  <span>{{date.state}}</span>
                </div>
              </div>
              <span class="text_46">{{date.text}}</span>
              <div class="bottom-text-wrapper flex-col items-center">
                <span>{{date.mark}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 线图 -->
        <div class="flex-col group_25">
          <span class="text_75">维修统计</span>
          <div class="btns"><button :class="{act:btnl}" @click="btnll()">月</button><button :class="{act:btnr}" @click="btnrr()">年</button></div>
          <div id="main" style="width: 100%;height: 400px;margin-top:30px"></div>
          <span class="text_113">维保统计</span>
          <div class="btns"><button :class="{act:btnl1}" @click="btnll1()">月</button><button :class="{act:btnr1}" @click="btnrr1()">年</button></div>
          <div id="main1" style="width: 100%;height: 400px;margin-top:30px"></div>
          <span class="text_134">年检统计</span>
          <div class="btns"><button :class="{act:btnl2}" @click="btnll2()">月</button><button :class="{act:btnr2}" @click="btnrr2()">年</button></div>
          <div id="main2" style="width: 100%;height: 400px;margin-top:30px"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import {myline,mylines} from '@/api/Maintenance/My/my'
export default {
  data() {
    return {
      charts: '',
      datatime:[],
      opinionData: [],
      opinionDatas: [],
      datatime1:[],
      opinionData1: [],
      opinionDatas1: [],
      datatime2:[],
      opinionData2: [],
      opinionDatas2: [],
      list1: [{ item: '一' }, { item: '二' }, { item: '三' }, { item: '四' }, { item: '五' }, { item: '六' },{ item: '日' }],
      list5: [
        { date: '2021-10-20 ' },
        // {
        //   date: null,
        //   time: '10:00',
        //   icon: require('../../../assets/icon46.png'),
        //   title: 'A座01梯',
        //   state: '已完成',
        //   text: '沙河口区 南海大道1000号花园城数码大厦A座',
        //   mark: '维保'
        // },
      ],
      list2: [{ item: '25' }, { item: '26' }, { item: '27' }, { item: '28' }, { item: '29' }, { item: '30' }, { item: '31' }],
      list3: [
        {
          icon: require('../../../assets/icon47.png'),
          title: '日常维保',
          numb: '0'
        },
        {
          icon: require('../../../assets/icon48.png'),
          title: '报警维修',
          numb: '0'
        },
        {
          icon: require('../../../assets/icon49.png'),
          title: '电梯年检',
          numb: '0'
        },
        {
          icon: require('../../../assets/icon49.png'),
          title: '紧急救援',
          numb: '0'
        }
      ],
      list4: [
        {
          title: '接收工单',
          tips: '0单',
          icon: require('../../../assets/icon51.png')
        },
        {
          title: '处理工单',
          tips: '0单',
          icon: require('../../../assets/icon52.png')
        },
        {
          title: '超期工单',
          tips: '0单',
          icon: require('../../../assets/icon53.png')
        },
        {
          title: '完成工单',
          tips: '0单',
          icon: require('../../../assets/icon54.png')
        }
      ],
      title: '工作统计',
      date: '2022',
      title_1: '今日待办',
      title_2: '工单统计',
      title_3: '工作日志',
      str:3,
      btnl:true,
      btnr:false,
      btnl1:true,
      btnr1:false,
      btnl2:true,
      btnr2:false,
    }
  },
  methods: {
    drawLine() {
      this.charts = echarts.init(document.getElementById('main'))
      this.charts.setOption({
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['接收订单', '完成订单']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.datatime
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '接收订单',
            type: 'line',
            stack: '总量',
            data: this.opinionData
          },
          {
            name: '完成订单',
            type: 'line',
            stack: '总量',
            data: this.opinionDatas
          }
        ]
      })
    },
    drawLine1() {
      this.charts = echarts.init(document.getElementById('main1'))
      this.charts.setOption({
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['接收订单', '完成订单']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.datatime1
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '接收订单',
            type: 'line',
            stack: '总量',
            data: this.opinionData1
          },
          {
            name: '完成订单',
            type: 'line',
            stack: '总量',
            data: this.opinionDatas1
          }
        ]
      })
    },
    drawLine2() {
      this.charts = echarts.init(document.getElementById('main2'))
      this.charts.setOption({
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['接收订单', '完成订单']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.datatime2
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '接收订单',
            type: 'line',
            stack: '总量',
            data: this.opinionData2
          },
          {
            name: '完成订单',
            type: 'line',
            stack: '总量',
            data: this.opinionDatas2
          }
        ]
      })
    },
    btnll(){
      this.btnl=true
      this.btnr=false
      mylines({type:'maintenance',date:'today'}).then(res=>{
        console.log(res.data.Data);
        this.opinionData=[]
        this.opinionDataS=[]
        this.datatime=[]
        for(var i=0;i<res.data.Data.length;i++){
          this.datatime.push(res.data.Data[i].date)
          this.opinionData.push(res.data.Data[i].count1)//接收
          this.opinionDataS.push(res.data.Data[i].count3)//完成
        }
        this.drawLine()
      })
    },
    btnrr(){
      this.btnl=false
      this.btnr=true
      mylines({type:'maintenance',date:'year'}).then(res=>{
        // console.log(res.data.Data);
        this.opinionData=[]
        this.opinionDataS=[]
        this.datatime=[]
        for(var i=0;i<res.data.Data.length;i++){
          this.datatime.push(res.data.Data[i].time)
          this.opinionData.push(res.data.Data[i].count1)//接收
          this.opinionDataS.push(res.data.Data[i].count3)//完成
        }
        this.drawLine()
      })
    },
    btnll1(){
      this.btnl1=true
      this.btnr1=false
      mylines({type:'annual_inspection',date:'today'}).then(res=>{
        // console.log(res.data.Data);
        this.opinionData1=[]
        this.opinionDataS1=[]
        this.datatime1=[]
        for(var i=0;i<res.data.Data.length;i++){
          this.datatime1.push(res.data.Data[i].date)
          this.opinionData1.push(res.data.Data[i].count1)//接收
          this.opinionDataS1.push(res.data.Data[i].count3)//完成
        }
        this.drawLine1()
      })
    },
    btnrr1(){
      this.btnl1=false
      this.btnr1=true
      mylines({type:'annual_inspection',date:'year'}).then(res=>{
            // console.log(res.data.Data);
            this.opinionData1=[]
            this.opinionDataS1=[]
            this.datatime1=[]
            for(var i=0;i<res.data.Data.length;i++){
              this.datatime1.push(res.data.Data[i].time)
              this.opinionData1.push(res.data.Data[i].count1)//接收
              this.opinionDataS1.push(res.data.Data[i].count3)//完成
            }
            this.drawLine1()
      })
    },
    btnll2(){
      this.btnl1=true
      this.btnr1=false
      mylines({type:'alarm',date:'today'}).then(res=>{
        // console.log(res.data.Data);
        this.opinionData2=[]
        this.opinionDataS2=[]
        this.datatime2=[]
        for(var i=0;i<res.data.Data.length;i++){
          this.datatime2.push(res.data.Data[i].date)
          this.opinionData2.push(res.data.Data[i].count1)//接收
          this.opinionDataS2.push(res.data.Data[i].count3)//完成
        }
        this.drawLine2()
      })
    },
    btnrr2(){
      this.btnl2=false
      this.btnr2=true
      mylines({type:'alarm',date:'year'}).then(res=>{
        // console.log(res.data.Data);
        this.opinionData2=[]
        this.opinionDataS2=[]
        this.datatime2=[]
        for(var i=0;i<res.data.Data.length;i++){
          this.datatime2.push(res.data.Data[i].time)
          this.opinionData2.push(res.data.Data[i].count1)//接收
          this.opinionDataS2.push(res.data.Data[i].count3)//完成
        }
        this.drawLine2()
      })
    },
    lists(item,i){
      this.str=i
      this.list5=[ { date: '' },]
      this.list5[0].date=this.date+'-'+this.list2[this.str].item
      // 点击日历出现的东西
      myline({time:this.date+'-'+item}).then(res=>{
        this.list3[0].numb=res.data.Data.maintenance
        this.list3[1].numb=res.data.Data.alarm
        this.list3[2].numb=res.data.Data.annual_inspection
        this.list3[3].numb=res.data.Data.rescue
        this.list4[0].tips=res.data.Data.receive
        this.list4[1].tips=res.data.Data.Arrive
        this.list4[2].tips=res.data.Data.Success
        this.list4[3].tips=res.data.Data.Timeout
        for(var i=0;i<res.data.Data.operation.length;i++){
          if(res.data.Data.operation[i].order_type==='alarm'){
            var order='报警'
          }else if(res.data.Data.operation[i].annualInspection){
            var order='年检'
          }else if(res.data.Data.operation[i].maintenance){
            var order='维保'
          }else if(res.data.Data.operation[i].rescue){
            var order='救援'
          }
          this.list5.push(
            {
              date:null,
              id: res.data.Data.operation[i].id,
              time: res.data.Data.operation[i].time,
              icon: require('../../../assets/icon46.png'),
              title: res.data.Data.operation[i].number,
              state: res.data.Data.operation[i].state,
              text: res.data.Data.operation[i].address,
              mark: order
            },
          )
        }
      })
    },
    // 深层判断就是点击后跳转到相应的页面
    tz(state,mark,id){
      if(mark==='维保'){
        if(state==='待接单'){
          this.$router.push('/maintenance/maintenance/receive/'+id)
        }else if(state==='待处理'){
          this.$router.push('/maintenance/maintenance/order/'+id)
        }else if(state==='处理中'){
          this.$router.push('/maintenance/maintenance/arrive/'+id)
        }else if(state==='已完成'){
          this.$router.push('/maintenance/maintenance/success/'+id)     
        }
      }else if(mark==='报警'){
       if(state==='待接单'){
          this.$router.push('/maintenance/alarm/receive/'+id)
        }else if(state==='待处理'){
          this.$router.push('/maintenance/alarm/order/'+id)
        }else if(state==='处理中'){
          this.$router.push('/maintenance/alarm/arrive/'+id)
        }else if(state==='已完成'){
          this.$router.push('/maintenance/alarm/success/'+id)     
        }
      }else if(mark==='救援'){
        if(state==='待接单'){
          this.$router.push('/maintenance/rescue/receive/'+id)
        }else if(state==='待处理'){
          this.$router.push('/maintenance/rescue/order/'+id)
        }else if(state==='处理中'){
          this.$router.push('/maintenance/rescue/arrive/'+id)
        }else if(state==='已完成'){
          this.$router.push('/maintenance/rescue/success/'+id)     
        }
      }else if(mark==='年检'){
        if(state==='待接单'){
          this.$router.push('/maintenance/annual-inspection/receive/'+id)
        }else if(state==='待处理'){
          this.$router.push('/maintenance/annual-inspection/order/'+id)
        }else if(state==='处理中'){
          this.$router.push('/maintenance/annual-inspection/arrive/'+id)
        }else if(state==='已完成'){
          this.$router.push('/maintenance/annual-inspection/success/'+id)     
        }
      }
    },
    getWeekTime() {
    // var new_Date = new Date(2021,11,10) //获取指定日期当周的一周日期
    var new_Date = new Date() //获取本周一周日期
    var timesStamp = new_Date.getTime();
    var currenDay = new_Date.getDay();
    var dates = [];
      for (var i = 0; i < 7; i++) {
          var das = new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - (currenDay + 6) % 7)).toLocaleDateString();
          das.replace(/[年月]/g, '.').replace(/[日上下午]/g, '');
          dates.push(das);
      }
      return dates;
    }

  },
  // watch:{
  //   'datatime':function(){
  //     this.drawLine()
  //   }
  // },
  mounted() {
    let date = new Date(+new Date() + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
    var arr=date.split(" ")
    var month=arr[0].split("-")
    this.date=month[0]+'-'+month[1]
    let days = this.getWeekTime() // 获取本周开始日期
    var str = [];
    for(var i=0;i<days.length;i++){
        var day = days[i].split('/');
        var sky = day[2];
        str.push({item:parseInt(sky)});
    }

    this.list2=str
    this.str = new Date().getDay() -1;


    this.list5[0].date=this.date+'-'+this.list2[this.str].item
    myline({time:this.date+'-'+this.list2[this.str].item}).then(res=>{
      // console.log(res);
      this.list3[0].numb=res.data.Data.maintenance
      this.list3[1].numb=res.data.Data.alarm
      this.list3[2].numb=res.data.Data.annual_inspection
      this.list3[3].numb=res.data.Data.rescue
      this.list4[0].tips=res.data.Data.receive
      this.list4[1].tips=res.data.Data.Arrive
      this.list4[2].tips=res.data.Data.Success
      this.list4[3].tips=res.data.Data.Timeout
      for(var i=0;i<res.data.Data.operation.length;i++){
        if(res.data.Data.operation[i].order_type==='alarm'){
          var order='报警'
        }else if(res.data.Data.operation[i].annualInspection){
          var order='年检'
        }else if(res.data.Data.operation[i].maintenance){
          var order='维保'
        }else if(res.data.Data.operation[i].rescue){
          var order='救援'
        }
        this.list5.push(
          {
            date:null,
            id: res.data.Data.operation[i].id,
            time: res.data.Data.operation[i].time,
            icon: require('../../../assets/icon46.png'),
            title: res.data.Data.operation[i].number,
            state: res.data.Data.operation[i].state,
            text: res.data.Data.operation[i].address,
            mark: order
          },
        )
      }
    })
    // 维修统计
    mylines({type:'maintenance',date:'today'}).then(res=>{
      // console.log(res.data.Data);
      this.opinionData=[]
      this.opinionDataS=[]
      this.datatime=[]
      for(var i=0;i<res.data.Data.length;i++){
        this.datatime.push(res.data.Data[i].date)
        this.opinionData.push(res.data.Data[i].count1)//接收
        this.opinionDataS.push(res.data.Data[i].count3)//完成
      }
      this.drawLine()
    })
    // 维保统计

    mylines({type:'annual_inspection',date:'today'}).then(res=>{
      // console.log(res.data.Data);
      this.opinionData1=[]
      this.opinionDataS1=[]
      this.datatime1=[]
      for(var i=0;i<res.data.Data.length;i++){
        this.datatime1.push(res.data.Data[i].date)
        this.opinionData1.push(res.data.Data[i].count1)//接收
        this.opinionDataS1.push(res.data.Data[i].count3)//完成
      }
      this.drawLine1()
    })
    // 年检统计
    mylines({type:'alarm',date:'today'}).then(res=>{
      // console.log(res.data.Data);
      this.opinionData2=[]
      this.opinionDataS2=[]
      this.datatime2=[]
      for(var i=0;i<res.data.Data.length;i++){
        this.datatime2.push(res.data.Data[i].date)
        this.opinionData2.push(res.data.Data[i].count1)//接收
        this.opinionDataS2.push(res.data.Data[i].count3)//完成
      }
      this.drawLine2()
    })

    
  }
}
</script>

<style scoped>
@import '../../../assets/common.css';
.list {
  margin-top: 0.13rem;
  padding: 0 0.8rem;
}
.list-item {
  margin: 0.31rem 0;
  color: rgb(0, 0, 0);
  font-size: 0.88rem;
  line-height: 0.19rem;
  white-space: nowrap;
}
.list-item_4 {
  margin-left: 0.13rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 0.81rem;
  white-space: nowrap;
}
.list-item_1 {
  flex: 1 1 2.5rem;
  color: rgb(255, 255, 255);
  font-size: 0.88rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.list-item_2 {
  padding: 0.69rem 0.19rem 0.69rem 0.63rem;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/61ba9edd5a7e3f0310429098/61baa7216b57d10012a731ae/16421783480958135203.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.list-item_3 {
  padding: 1.31rem 1.09rem 1.25rem 1.28rem;
}
.top-text-wrapper {
  padding: 0.88rem 0;
  background: #fff;
  border-radius: 0.4rem;
  margin: 0 0.2rem;
}
.image_2 {
  align-self: center;
  width: 0.5rem;
  height: 0.5rem;
  background: #ffc35e;
  border-radius: 50%;
  margin-top: -0.2rem;
}
.image_6 {
  width: 2.13rem;
  height: 2.13rem;
}
.text_19 {
  margin-left: 0.44rem;
  align-self: center;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
}
.text_21 {
  margin-left: 0.75rem;
  align-self: center;
  color: rgb(0, 88, 217);
  font-size: 0.88rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.left-group {
  margin-top: 0.13rem;
}
.image_12 {
  margin-bottom: 0.13rem;
  width: 2.19rem;
  height: 2.19rem;
}
.text_10 {
  text-align: center;
  color: #000;
}
.text_30 {
  color: rgb(0, 0, 0);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
}
.text_32 {
  margin-top: 0.5rem;
  color: rgb(211, 211, 211);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
}
.page {
  padding-top: 0.063rem;
  background-color: rgb(247, 246, 252);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.section_1 {
  padding: 0.88rem 0.81rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
  background-color: rgb(237, 237, 237);
}
.group_1 {
  height: 146.56rem;
  overflow-y: auto;
}
.image_1 {
  margin-right: 0.25rem;
  align-self: center;
  width: 1.13rem;
  height: 0.25rem;
}
.text-wrapper {
  padding: 4.94rem 0 4.88rem;
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  line-height: 1.44rem;
  white-space: nowrap;
  background: url(../../../assets/imgbg7.png) center top no-repeat;
  background-size: 100% 100%;
}
.section_2 {
  margin-top: -1rem;
  padding: 1.19rem 1.44rem 0.31rem;
  background: #f7f6fc;
  border-radius: 1rem;
  position: relative;
}
.group_3 {
  padding: 0.75rem 1rem 1.75rem 1.19rem;
}
.image {
  margin-bottom: 0.13rem;
  width: 0.81rem;
  height: 0.81rem;
}
.text {
  margin-left: 0.69rem;
}
.text_1 {
  margin-left: 1.5rem;
}
.text_2 {
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
  line-height: 1.44rem;
  white-space: nowrap;
}
.group_2 {
  margin-top: 1.25rem;
  justify-content: space-between;
}
.group_8 {
  margin-top: 1.38rem;
  padding: 0 0.063rem 1.25rem;
  position: relative;
}
.group_25 {
  margin-top: 1.38rem;
  padding: 0 0.063rem;
}
.text_18 {
  margin-top: 1.13rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
}
.list_2 {
  height: 7.75rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 0.81rem;
  grid-column-gap: 0.94rem;
  margin-bottom: 1rem;
}
.text_29 {
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
}
.list_3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 0.97rem;
  background: #fff;
  margin-top: 1rem;
  border-radius: 0.6rem;
  position: relative;
}
.list_3::before {
  content: '';
  width: 90%;
  height: 1px;
  box-sizing: border-box;
  padding: 0 10%;
  background: #ccc;
  position: absolute;
  top: 50%;
  left: 5%;
}
.list_3::after {
  content: '';
  width: 1px;
  height: 80%;
  box-sizing: border-box;
  padding: 10% 0;
  background: #ccc;
  position: absolute;
  left: 50%;
  top: 10%;
}
.text_40 {
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
  margin-bottom: 1rem;
}
.text_75 {
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
}
.text_113 {
  margin-top: 1.13rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
}
.text_134 {
  margin-top: 1.06rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
}
.view_3 {
  margin-top: initial;
}

.list-item_4 {
  margin-left: 0.13rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 0.81rem;
  white-space: nowrap;
}
.left-group_1 {
  margin-top: 0.94rem;
  flex: 1 1 2.88rem;
  color: rgb(0, 14, 29);
  font-size: 0.88rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.right-section {
  margin-left: 0.88rem;
  margin-right: 0.19rem;
  padding: 0.94rem 0.63rem 1.13rem;
  flex: 1 1 2.88rem;
  filter: drop-shadow(0px 0.063rem 0.75rem rgba(153, 153, 153, 0.1));
  background: #fff;
  border-radius: 0.6rem;
  height: 6.88rem;
}
.image_17 {
  margin-top: 0.31rem;
  width: 0.81rem;
  height: 0.81rem;
}
.bottom-section {
  margin-top: 0.38rem;
  background-color: rgb(218, 226, 226);
  width: 0.063rem;
  height: 4.38rem;
}
.text_46 {
  margin-top: 0.69rem;
  color: rgb(153, 153, 153);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.bottom-text-wrapper {
  margin-top: 0.75rem;
  padding: 0.25rem 0;
  color: rgb(255, 255, 255);
  font-size: 0.63rem;
  line-height: 0.63rem;
  white-space: nowrap;
  background: url(../../../assets/icon2.png) center no-repeat;
  width: 2.38rem;
}
.text_43 {
  align-self: center;
  color: rgb(0, 0, 0);
  font-size: 0.94rem;
  line-height: 0.94rem;
  white-space: nowrap;
}
.right-text-wrapper {
  margin-right: 0.25rem;
  padding: 0.38rem 0;
  color: rgb(0, 104, 255);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
  background: #f5f5f5;
  border-radius: 1rem;
  width: 3.88rem;
  height: 1.5rem;
}
.group_13 {
  margin-left: initial;
  color: initial;
  font-size: initial;
  line-height: initial;
  white-space: initial;
  margin-top: 1rem;
  padding: 0 0.063rem;
}
.active {
  background: #0058d9;
}
.active span {
  color: #fff !important;
}
.btns{
  margin-top: 0.8rem;
}
.btns button{
  background: none;
  margin-right: 0.3rem;
  padding:0 0.5rem;
  border-radius: 0.3rem;
  border-color: #b4b1b1;
}
.btns .act{
  background: #8b8b8b;
  color: #fff;
}
</style>

